<template>
  <div></div>
</template>
<script>
export default {
  props: ["language"],
  data: function () {
    return {
      from: null,
    };
  },
  mounted() {
    localStorage.setItem("selectedLang", this.language);
    this.$router.push({ path: this.from.path });
    //console.log(this.$route)
    //this.$router.push({ path:'/' })
    //this.$router.go(-1)
    //window.location.reload()
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.from = from;
      console.log(from)
    });
  },
};
</script>